import React, { useState, useEffect } from "react";
import loginSignupImage from "../assets/login-animation.gif";
import { BiShow, BiHide } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import { BsEmojiSmileUpsideDown } from "react-icons/bs";
import { ImagetoBase64 } from "../utility/ImagetoBase64";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { loginRedux } from "../redux/userSlice";

function UpdateAccount() {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const userData = useSelector((state) => state.user);
    const [data, setData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        image: ""
    });

    useEffect(() => {
        setData(userData);
    }, []);

    const handleShowPassword = () => {
        setShowPassword(prev => !prev);
    };

    const handleShowConfirmPassword = () => {
        setShowConfirmPassword(prev => !prev);
    };

    const handleOnChange = e => {
        const { name, value } = e.target;
        setData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleUploadProfileImage = async e => {
        const data = await ImagetoBase64(e.target.files[0]);
        setData(prev => ({
            ...prev,
            image: data
        }));
    };

    const handleSubmit = async e => {
        e.preventDefault();
        const { firstName, lastName, email, password, confirmPassword } = data;
        if (firstName && email) {
            if (password === confirmPassword) {
                try {
                    const fetchData = await fetch(`${process.env.REACT_APP_SERVER_DOMAIN}/update-account/${userData._id}`, {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json"
                            // Include any necessary headers for authentication
                        },
                        body: JSON.stringify({ firstName, lastName, email, password, image: data.image })
                    });
                    const responseData = await fetchData.json();
                    toast(responseData.message);
                    if (responseData.alert) {
                        dispatch(loginRedux(responseData))
                        setTimeout(() => {
                            navigate("/")
                        }, 1000);
                    }
                } catch (error) {
                    console.error("Error updating account:", error);
                    // Handle error (e.g., show error message)
                }
            } else {
                toast("Password and confirm password do not match");
            }
        } else {
            toast("Please enter required fields");
        }
    };

    return (
        <div className="p-3 md:p-4">
            <div className="w-full max-w-sm bg-white m-auto flex flex-col p-4">
                <div className="w-20 h-20 overflow-hidden rounded-full drop-shadow-md shadow-md m-auto relative ">
                    <img src={data.image ? data.image : loginSignupImage} className="w-full h-full" alt="Profile" />

                    <label htmlFor="profileImage">
                        <div className="absolute bottom-0 h-1/3 bg-slate-500 bg-opacity-50 w-full text-center cursor-pointer">
                            <p className="text-sm p-1 text-white">Upload</p>
                        </div>
                        <input type={"file"} id="profileImage" accept="image/*" className="hidden" onChange={handleUploadProfileImage} />
                    </label>
                </div>

                <form className="w-full py-3 flex flex-col" onSubmit={handleSubmit}>
                    <label htmlFor="firstName">First Name</label>
                    <input
                        type={"text"}
                        id="firstName"
                        name="firstName"
                        className="mt-1 mb-2 w-full bg-slate-200 px-2 py-1 rounded focus-within:outline-blue-300"
                        value={data.firstName}
                        onChange={handleOnChange}
                    />

                    <label htmlFor="lastName">Last Name</label>
                    <input
                        type={"text"}
                        id="lastName"
                        name="lastName"
                        className="mt-1 mb-2 w-full bg-slate-200 px-2 py-1 rounded focus-within:outline-blue-300"
                        value={data.lastName}
                        onChange={handleOnChange}
                    />

                    <label htmlFor="email">Email</label>
                    <input
                        type={"email"}
                        id="email"
                        name="email"
                        className="mt-1 mb-2 w-full bg-slate-200 px-2 py-1 rounded focus-within:outline-blue-300"
                        value={data.email}
                        onChange={handleOnChange}
                    />

                    <label htmlFor="password">Password</label>
                    <div className="flex px-2 py-1 bg-slate-200 rounded mt-1 mb-2 focus-within:outline focus-within:outline-blue-300">
                        <input
                            type={showPassword ? "text" : "password"}
                            id="password"
                            name="password"
                            className=" w-full bg-slate-200 border-none outline-none "
                            value={data.password}
                            onChange={handleOnChange}
                        />
                        <span className="flex text-xl cursor-pointer" onClick={handleShowPassword}>
                            {showPassword ? <BiShow /> : <BiHide />}
                        </span>
                    </div>

                    <label htmlFor="confirmPassword">Confirm Password</label>
                    <div className="flex px-2 py-1 bg-slate-200 rounded mt-1 mb-2 focus-within:outline focus-within:outline-blue-300">
                        <input
                            type={showConfirmPassword ? "text" : "password"}
                            id="confirmPassword"
                            name="confirmPassword"
                            className=" w-full bg-slate-200 border-none outline-none "
                            value={data.confirmPassword}
                            onChange={handleOnChange}
                        />
                        <span className="flex text-xl cursor-pointer" onClick={handleShowConfirmPassword}>
                            {showConfirmPassword ? <BiShow /> : <BiHide />}
                        </span>
                    </div>

                    <button className="w-full max-w-[150px] m-auto bg-red-500 hover:bg-red-600 cursor-pointer text-white text-xl font-medium text-center py-1 rounded-full mt-4">
                        Update
                    </button>
                </form>
                <p className="text-left text-sm mt-2">
                    Want to cancel?{" "}
                    <Link to={"/"} className="text-red-500 underline">
                        Go back to home
                    </Link>
                </p>
            </div>
        </div>
    );
}

export default UpdateAccount;
