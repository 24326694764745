import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  email: "",
  firstName: "",
  image: "",
  lastName: "",
  _id: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginRedux: (state, action) => {
      const { _id, firstName, lastName, email, image } = action.payload.data;
      return {
        ...state,
        _id,
        firstName,
        lastName,
        email,
        image,
      };
    },
    logoutRedux: (state) => initialState,
  },
});

export const { loginRedux, logoutRedux } = userSlice.actions;

export default userSlice.reducer;