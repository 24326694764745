import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function OrderList() {
    const [orders, setOrders] = useState([]);
    const userData = useSelector(state => state.user);

    const fetchOrders = async () => {
        try {
            let userId = '';
            if (userData.email !== process.env.REACT_APP_ADMIN_EMAIL) userId = userData._id;
            const response = await fetch(`${process.env.REACT_APP_SERVER_DOMAIN}/orders/${userId}`);
            if (!response.ok) throw new Error('Failed to fetch orders');
            const data = await response.json();
            setOrders(data.orders);
        } catch (error) {
            console.error('Error fetching orders:', error);
        }
    };

    useEffect(() => {
        fetchOrders();
    }, []);

    const handleOrderStatusChange = async (orderId, newStatus) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_DOMAIN}/orders/${orderId}/status`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ status: newStatus })
            });
            if (!response.ok) throw new Error('Failed to update order status');
            fetchOrders();
        } catch (error) {
            console.error('Error updating order status:', error);
        }
    };

    const [expandedOrder, setExpandedOrder] = useState(null);

    const toggleOrderExpansion = orderId => {
        setExpandedOrder(prevState => (prevState === orderId ? null : orderId));
    };

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-3xl font-bold mb-4">Order List</h1>
            <ul>
                {orders.map(order => (
                    <li key={order._id} className="mb-4 bg-white rounded-lg shadow-md p-4">
                        <div className="flex justify-between items-center border-b pb-2">
                            <div>
                                <div className="text-lg font-semibold">Order ID: {order._id}</div>
                                <div>Amount: <span className="text-red-500">₹</span>{order.amount}</div>
                                <div>Payment Status: {order.paymentStatus}</div>
                            </div>
                            <div>
                                {userData.email !== process.env.REACT_APP_ADMIN_EMAIL ? order.orderStatus : 
                                    (<select
                                        value={order.orderStatus}
                                        onChange={e => handleOrderStatusChange(order._id, e.target.value)}
                                        className="px-3 py-1 border rounded"
                                    >
                                        <option value="pending">Pending</option>
                                        <option value="accepted">Accepted</option>
                                        <option value="preparing">Preparing</option>
                                        <option value="on the way">On The Way</option>
                                        <option value="delivered">Delivered</option>
                                    </select>)
                                }
                            </div>
                        </div>
                        {expandedOrder === order._id && (
                            <div className="mt-4">
                                <h3 className="text-lg font-semibold mb-2">Items</h3>
                                <ul>
                                    {order.items?.map((item, index) => (
                                        <li key={index}>
                                            {item.name} x {item.quantity} - <span className="text-red-500">₹</span>{item.price}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        <button
                            className="mt-2 text-blue-600 hover:underline"
                            onClick={() => toggleOrderExpansion(order._id)}
                        >
                            {expandedOrder === order._id ? 'Hide Items' : 'Show Items'}
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default OrderList;
