import React, { useEffect } from 'react'
import { useDispatch } from "react-redux";
import { setEmptyCart } from "../redux/productSlide";
const Success = () => {
  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(setEmptyCart())
  },[])
  return (
    <div className='bg-green-200 w-full max-w-md m-auto h-36 flex justify-center items-center font-semibold text-lg'>
        <p>Payment is Successful</p>
    </div>
  )
}

export default Success